import React from "react";
import { PageWrapper } from "~components/Core";
import HeaderButton from "~sections/marketing/Header";
import DynamicComponent from "~components/dynamicComponent";
import SbEditable from "storyblok-react";
import FooterSix from "~components/Footer";

import useStoryblok from "../lib/storyblok";
import SEO from "../components/Seo";

import { graphql } from "gatsby";

const Page = ({ pageContext, location }) => { 
  let story = pageContext.story
  story = useStoryblok(story, location)
 
  const components = story.content.body.map((blok) => {
    return <DynamicComponent blok={blok} key={blok._uid} />;
  });

  const header = {
    headerClasses: `site-header site-header--menu-start  dark-header site-header--sticky`,
    containerFluid: false,
    darkLogo: true,

    // customLogo: Images.HeaderLogo,
    buttonBlock: (
      <HeaderButton
        className="ms-auto d-none d-xs-inline-flex"
        btnOneText="GET STARTED"
        mr="15px"
        mrLG="0"
      />
    ),
  };

  return (
    <PageWrapper headerConfig={header}>
      <SEO title={story.content.seo_title} description={story.content.seo_title}/>
      <SbEditable content={story.content}>{components}</SbEditable>
      <FooterSix/>

    </PageWrapper>
  );
}

export default Page
